// extracted by mini-css-extract-plugin
export var blogBlock = "styles-module--blog-block--Gu8co";
export var blogBlockModal = "styles-module--blog-block-modal--k+xQL";
export var blogBlockModalWrapper = "styles-module--blog-block-modal-wrapper--X4ALw";
export var blogBlockModalWrapperClose = "styles-module--blog-block-modal-wrapper-close--pr1jm";
export var blogBlockModalWrapperContainer = "styles-module--blog-block-modal-wrapper-container---6ygw";
export var blogBlockModalWrapperContainerTitle = "styles-module--blog-block-modal-wrapper-container-title--vENhc";
export var blogBlockModalWrapperContainerCheck = "styles-module--blog-block-modal-wrapper-container-check--2it8j";
export var blogBlockModalWrapperContainerButton = "styles-module--blog-block-modal-wrapper-container-button--74e8n";
export var blogBlockModalWrapperContainerButtonSelect = "styles-module--blog-block-modal-wrapper-container-button-select--Nb9rO";
export var blogBlockModalWrapperContainerLang = "styles-module--blog-block-modal-wrapper-container-lang--h0D-m";
export var blogBlockLink = "styles-module--blog-block-link--aVnwb";
export var blogBlockCard = "styles-module--blog-block-card--dcPX9";
export var blogBlockIn = "styles-module--blogBlockIn--1NagA";
export var blogBlockCardImage = "styles-module--blog-block-card-image--Qx5nb";
export var blogBlockCardInner = "styles-module--blog-block-card-inner--7h1CK";
export var blogBlockCardInfo = "styles-module--blog-block-card-info--8g-yr";
export var blogBlockCardInfoDate = "styles-module--blog-block-card-info-date--a2kWC";
export var blogBlockCardInfoType = "styles-module--blog-block-card-info-type--+rDX7";
export var blogBlockCardTitle = "styles-module--blog-block-card-title--e+zYi";
export var blogBlockCardText = "styles-module--blog-block-card-text--I3QNZ";
export var blogBlockCardFooter = "styles-module--blog-block-card-footer--r2iFY";
export var blogBlockCardAuthor = "styles-module--blog-block-card-author--JiG3d";
export var blogBlockCardAuthorImage = "styles-module--blog-block-card-author-image--zDT5b";
export var blogBlockCardAuthorName = "styles-module--blog-block-card-author-name--6CutB";
export var blogBlockContainer = "styles-module--blog-block-container--zhvHo";