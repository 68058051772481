import React, { useState, useLayoutEffect } from 'react'
import { Facebook, Twitter, LinkedIn } from '@material-ui/icons'
import { Link } from 'gatsby'

import Seo from '../components/Seo'
import { onePostPageConfigurator } from '../helpers/helpersForBlog'
import { transformToReact } from '../helpers/format/transformToRC'
import linkIcon from '../assets/images/svg/blogPage/Arrow.svg'

import close from '../assets/images/png/blogPage/Close.png'
import russia from '../assets/images/png/blogPage/RU.png'
import english from '../assets/images/png/blogPage/ENG.png'
import choose from '../assets/images/png/blogPage/Vector.png'

import * as styles from './styles.module.scss'

const Blog = ({ pageContext }) => {
  const [open, isOpen] = useState(true)
  const [lang, setLang] = useState('')

  let result = onePostPageConfigurator(pageContext)

  const handleChangeLanguage = async (e, key) => {
    if (key === 'russian') {
      await setLang('russian')
    } else {
      await setLang('english')
    }
  }

  const handleChangeIsOpen = () => {
    isOpen(false)
  }

  const handleChangeLanguageSet = () => {
    isOpen(false)
    localStorage.setItem('lang', JSON.stringify(lang))
  }

  const currentLanguage =
    typeof window !== 'undefined' && JSON.parse(localStorage.getItem('lang'))

  useLayoutEffect(() => {
    if (currentLanguage && currentLanguage.length > 0) {
      isOpen(false)
    }
  }, [])

  return (
    <>
      <Seo
        title={`Blog | ${result.titleForSeo}`}
        description={`${transformToReact(pageContext.data.bodyEn.raw)
          .substring(0, 200)
          .replace(/<[^>]+>/g, '')} ...`}
        image={result.thumb}
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
        blog
      />
      <div className={styles.blogBlock}>
        {open === true ? (
          <div className={styles.blogBlockModal}>
            <div className={styles.blogBlockModalWrapper}>
              <img
                onClick={handleChangeIsOpen}
                src={close}
                className={styles.blogBlockModalWrapperClose}
                alt="close"
              />
              <div className={styles.blogBlockModalWrapperContainer}>
                <p className={styles.blogBlockModalWrapperContainerTitle}>Select a language</p>
                <div>
                  {lang && lang === 'russian' ? (
                    <div className={styles.blogBlockModalWrapperContainerLang}>
                      <button onClick={(e) => handleChangeLanguage(e, 'russian')}>
                        <div className={styles.blogBlockModalWrapperContainerCheck}>
                          <div>
                            <img src={russia} alt="Russian language" />
                            <p>Russia</p>
                          </div>
                          <div>
                            <img src={choose} alt="Choose language" />
                          </div>
                        </div>
                      </button>
                    </div>
                  ) : (
                    <button onClick={(e) => handleChangeLanguage(e, 'russian')}>
                      <div className={styles.blogBlockModalWrapperContainerCheck}>
                        <div>
                          <img src={russia} alt="Russian language" />
                          <p>Russia</p>
                        </div>
                        <div>
                          <img src={choose} alt="Choose language" />
                        </div>
                      </div>
                    </button>
                  )}
                </div>
                <div>
                  {lang && lang === 'english' ? (
                    <div className={styles.blogBlockModalWrapperContainerLang}>
                      <button onClick={(e) => handleChangeLanguage(e, 'english')}>
                        <div className={styles.blogBlockModalWrapperContainerCheck}>
                          <div>
                            <img src={english} alt="English language" />
                            <p>English</p>
                          </div>
                          <div>
                            <img src={choose} alt="Choose language" />
                          </div>
                        </div>
                      </button>
                    </div>
                  ) : (
                    <button onClick={(e) => handleChangeLanguage(e, 'english')}>
                      <div className={styles.blogBlockModalWrapperContainerCheck}>
                        <div>
                          <img src={english} alt="English language" />
                          <p>English</p>
                        </div>
                        <div>
                          <img src={choose} alt="Choose language" />
                        </div>
                      </div>
                    </button>
                  )}
                </div>
                <div className={styles.blogBlockModalWrapperContainerButton}>
                  <button className={styles.blogBlockModalWrapperContainerButtonSelect} onClick={handleChangeLanguageSet}>Select</button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className={`${styles.blogBlockContainer} container`}>
          <div onClick={() => history.back()} className={styles.blogBlockLink}>
            <img src={linkIcon} alt="link back" />
            <Link to="/blog/" key="dsfsd34329">
              <span>All articles</span>
            </Link>
          </div>
          <div className={styles.blogBlockCard}>
            <section className={styles.blogBlockCardImage}>
              <img src={result.imgSrc} loading="eager" alt={result.title} />
            </section>
            <section className={styles.blogBlockCardInner}>
              <div className={styles.blogBlockCardInfo}>
                <span className={styles.blogBlockCardInfoDate}>
                  {result.data}
                </span>
                <span className={styles.blogBlockCardInfoType}>
                  #{result.category}
                </span>
              </div>
              <h1 className={styles.blogBlockCardTitle}>
                {currentLanguage && currentLanguage === 'russian' ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: result.titleRu,
                    }}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: result.title,
                    }}
                  />
                )}
              </h1>
              {currentLanguage && currentLanguage === 'russian' ? (
                <div
                  className={styles.blogBlockCardText}
                  dangerouslySetInnerHTML={{
                    __html: result.bodyRu,
                  }}
                />
              ) : (
                <div
                  className={styles.blogBlockCardText}
                  dangerouslySetInnerHTML={{
                    __html: result.body,
                  }}
                />
              )}
              <div className={styles.blogBlockCardFooter}>
                <div className={styles.blogBlockCardAuthor}>
                  <div className={styles.blogBlockCardAuthorImage}>
                    <img src={result.avatar} alt={result.peopleName} />
                  </div>
                  <span className={styles.blogBlockCardAuthorName}>
                    {result.authorName}
                  </span>
                </div>
                <span>Share Article:</span>
                <ul>
                  <li>
                    <a
                      href={`https://www.facebook.com/share.php?u=${result.link}`}
                      target="_blank"
                      className="icon fa-facebook"
                      aria-label="facebook"
                      rel="noreferrer noopener"
                    >
                      <Facebook />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://twitter.com/share?text=${result.link}`}
                      target="_blank"
                      className="icon fa-twitter"
                      aria-label="Twitter"
                      rel="noreferrer noopener"
                    >
                      <Twitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://www.linkedin.com/shareArticle?mini=true&url=${result.link}`}
                      target="_blank"
                      className="icon fa-linkedin"
                      aria-label="Linkedin"
                      rel="noreferrer noopener"
                    >
                      <LinkedIn />
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default Blog
